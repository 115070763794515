import React, {useEffect, useState} from "react";
import { Typography, Card, CardContent, Grid, Stack, TextField, Button, MenuItem, Snackbar, Alert } from "@mui/material";
import { getProfile, updateProfile } from "../global/api";
import { useNavigate } from 'react-router-dom';

const Profile = () => {
    const navigate = useNavigate();
    
    const [errorMessage, setErrorMessage] = useState('');
    const [snackOpen, setSnackOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [role, setRole] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [gender, setGender] = useState('');
    const [age, setAge] = useState('');
    const [ethnicity, setEthnicity] = useState('');
    const [location, setLocation] = useState('');
    const [education, setEducation] = useState('');
    const [maritalStatus, setMaritalStatus] = useState('');
    const [householdIncome, setHouseholdIncome] = useState('');

    useEffect(() => {
        setIsLoading(true);
        getProfile().then(res => {
            if (res && res.statusCode) {
                let statusCode = res.statusCode;
                if (statusCode === '200' && res.body) {
                    let body = res.body;
                    if (body.role) {
                        setRole(body.role);
                    }
                    if (body.phoneNumber) {
                        setPhoneNumber(body.phoneNumber);
                    }
                    if (body.gender) {
                        setGender(body.gender);
                    }
                    if (body.age) {
                        setAge(body.age);
                    }
                    if (body.ethnicity) {
                        setEthnicity(body.ethnicity);
                    }
                    if (body.location) {
                        setLocation(body.location);
                    }
                    if (body.education) {
                        setEducation(body.education);
                    }
                    if (body.maritalStatus) {
                        setMaritalStatus(body.maritalStatus);
                    }
                    if (body.householdIncome) {
                        setHouseholdIncome(body.householdIncome);
                    }
                }
            }
        })
        .finally(() => {
            setIsLoading(false);
        });
    }, []);

    function cancelHandler() {
        navigate('/');
    }

    function saveHandler() {
        const data = { 
            "role": role,
            "phoneNumber": phoneNumber,
            "gender": gender,
            "age": age,
            "ethnicity": ethnicity,
            "location": location,
            "education": education,
            "maritalStatus": maritalStatus,
            "householdIncome": householdIncome
        }

        setIsLoading(true);

        updateProfile(data)
            .then(res => {
                if (res && res.statusCode && res.statusCode === '200') {
                    navigate('/');
                }
                else {
                    if (res.message) {
                        showError(res.message);
                    }
                    else {
                        showError('Unknown error.  Try again.');
                    }
                }
            }).catch(err => {
                showError('Unable to save profile information');
                console.log(err);
            }).finally(() =>{
                setIsLoading(false);
            });
    }

    function showError(msg) {
        setSnackOpen(true);
        setErrorMessage(msg);
    }
    function hideError(msg) {
        setSnackOpen(false);
        setErrorMessage('');
    }

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        hideError();
    }

    let vertical = 'top';
    let horizontal = 'center';

    return (
        <Card style={{maxWidth: 950, margin: '0 auto', padding: '20px 5px'}}>
            <CardContent>
                <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical, horizontal}} key={vertical + horizontal}>
                    <Alert severity='error' onClose={handleSnackClose} sx={{width: '100%'}}>{errorMessage}</Alert>
                </Snackbar>
                <Typography gutterBottom variant='h5'>Profile</Typography>
                <Typography gutterBottom variant='body2' component='p' color='textSecondary'>Update your profile information</Typography>
                <Grid container spacing={1} mt={1} mb={1}>
                    <Grid xs={12} sm={6} item>
                        <TextField 
                            variant='outlined' 
                            label='Role'
                            select
                            fullWidth
                            value={role}
                            onChange={(e) => setRole(e.target.value)} 
                            disabled={isLoading}>
                                <MenuItem value={'executive'}>Executive</MenuItem>
                                <MenuItem value={'director'}>Director</MenuItem>
                                <MenuItem value={'manager'}>Manager</MenuItem>
                                <MenuItem value={'supervisor'}>Supervisor</MenuItem>
                                <MenuItem value={'individualContributor'}>Individual Contributor</MenuItem>
                                <MenuItem value={'selfEmployed'}>Self-Employed</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                        <TextField 
                            variant='outlined' 
                            label='Phone Number'
                            value={phoneNumber}
                            fullWidth
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            disabled={isLoading}>
                        </TextField>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                        <TextField
                            variant='outlined' 
                            label='Gender'
                            select
                            fullWidth
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            disabled={isLoading}>
                                <MenuItem value={'male'}>Male</MenuItem>
                                <MenuItem value={'female'}>Female</MenuItem>
                                <MenuItem value={'preferNot'}>Prefer not to answer</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                        <TextField
                            variant='outlined' 
                            label='Age'
                            select
                            fullWidth
                            value={age}
                            onChange={(e) => setAge(e.target.value)}
                            disabled={isLoading}>
                                <MenuItem value={'0-15'}>0 - 15 years old</MenuItem>
                                <MenuItem value={'16-30'}>16 - 30 years old</MenuItem>
                                <MenuItem value={'31-45'}>31 - 45 years old</MenuItem>
                                <MenuItem value={'46+'}>46+ years old</MenuItem>
                                <MenuItem value={'preferNot'}>Prefer not to answer</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                        <TextField
                            variant='outlined' 
                            label='Ethnicity'
                            select
                            fullWidth
                            value={ethnicity}
                            onChange={(e) => setEthnicity(e.target.value)}
                            disabled={isLoading}>
                                <MenuItem value={'caucasian'}>Caucasian</MenuItem>
                                <MenuItem value={'african-american'}>African-American</MenuItem>
                                <MenuItem value={'latino-hispanic'}>Latino or Hispanic</MenuItem>
                                <MenuItem value={'asian'}>Asian</MenuItem>
                                <MenuItem value={'native-american'}>Native American</MenuItem>
                                <MenuItem value={'native-hawaiian-pacific-islander'}>Native Hawaiian or Pacific Islander</MenuItem>
                                <MenuItem value={'two-or-more'}>Two or More</MenuItem>
                                <MenuItem value={'other-unkown'}>Other / Unknown</MenuItem>
                                <MenuItem value={'preferNot'}>Prefer not to answer</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                        <TextField
                            variant='outlined' 
                            label='Location'
                            select
                            fullWidth
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                            disabled={isLoading}>
                                <MenuItem value={'north-central-america'}>North America / Central America</MenuItem>
                                <MenuItem value={'south-america'}>South America</MenuItem>
                                <MenuItem value={'europe'}>Europe</MenuItem>
                                <MenuItem value={'africa'}>Africa</MenuItem>
                                <MenuItem value={'asia'}>Asia</MenuItem>
                                <MenuItem value={'australia'}>Australia</MenuItem>
                                <MenuItem value={'caribbean-islands'}>Caribbean Islands</MenuItem>
                                <MenuItem value={'pacific-islands'}>Pacific Islands</MenuItem>
                                <MenuItem value={'other'}>Other</MenuItem>
                                <MenuItem value={'preferNot'}>Prefer not to answer</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                        <TextField
                            variant='outlined' 
                            label='Education'
                            select
                            fullWidth
                            value={education}
                            onChange={(e) => setEducation(e.target.value)}
                            disabled={isLoading}>
                                <MenuItem value={'some-high-school'}>Some High School</MenuItem>
                                <MenuItem value={'high-school'}>High School</MenuItem>
                                <MenuItem value={'some-college-university'}>Some College or University</MenuItem>
                                <MenuItem value={'associate-degree'}>Associate Degree</MenuItem>
                                <MenuItem value={'bachelors-degree'}>Bachelor's Degree</MenuItem>
                                <MenuItem value={'masters-degree'}>Master's Degree</MenuItem>
                                <MenuItem value={'phd-or-higher'}>Ph.D. or higher</MenuItem>
                                <MenuItem value={'trade-school'}>Trade School</MenuItem>
                                <MenuItem value={'preferNot'}>Prefer not to answer</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                        <TextField
                            variant='outlined' 
                            label='Marital Status'
                            select
                            fullWidth
                            value={maritalStatus}
                            onChange={(e) => setMaritalStatus(e.target.value)}
                            disabled={isLoading}>
                                <MenuItem value={'married'}>Married</MenuItem>
                                <MenuItem value={'not-married'}>Not Married</MenuItem>
                                <MenuItem value={'preferNot'}>Prefer not to answer</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid xs={12} item>
                        <TextField
                            variant='outlined' 
                            label='Household Income'
                            select
                            fullWidth
                            value={householdIncome}
                            onChange={(e) => setHouseholdIncome(e.target.value)}
                            disabled={isLoading}>
                                <MenuItem value={'less-than-25'}>Less than $25,000 per year</MenuItem>
                                <MenuItem value={'25-50'}>$25,000 to $50,000 per year</MenuItem>
                                <MenuItem value={'50-100'}>$50,000 to $100,000 per year</MenuItem>
                                <MenuItem value={'100-200'}>$100,000 to $200,000 per year</MenuItem>
                                <MenuItem value={'200+'}>More than $200,000 per year</MenuItem>
                                <MenuItem value={'preferNot'}>Prefer not to answer</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
                <Stack direction='row' spacing={1}>
                    <Button variant='contained' color='primary' onClick={saveHandler} disabled={isLoading}>Save</Button>
                    <Button variant="contained" onClick={cancelHandler} disabled={isLoading}>Cancel</Button>
                </Stack>
            </CardContent>
        </Card>
    )
};

export default Profile;