import React, {useState} from "react";
import { AppBar, Avatar, Container, Toolbar, Typography, Drawer, List, ListItem, ListItemText, ListItemButton, Divider, ListItemIcon } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ImageIcon from '@mui/icons-material/Image';
import logo from './spectrum.png';

const Header = () => {  
    const [drawerOpen, setDrawerOpen] = useState(false);

    const bold = {
        fontWeight: 'bold'
    }

    function toggleDrawer(open) {
        setDrawerOpen(open);
    }

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar>
                    <Avatar alt="Spectrum" src={logo} />
                    <Typography component="div" sx={{marginLeft: 2, flexGrow: 1}}>Spectrum Development</Typography>
                    {/* <Typography>
                        <Link color="inherit" href="/">Create Image</Link>
                    </Typography> */}
                    <MenuIcon onClick={() => toggleDrawer(true)} />
                </Toolbar>
                <Drawer anchor='right' open={drawerOpen} onClose={() => toggleDrawer(false)} p={2} PaperProps={{sx: {textAlign: 'center'}}}>
                        <List>
                            <ListItemButton component='a' href='/profile'>
                                <ListItemIcon><AccountCircleIcon /></ListItemIcon>
                                <ListItemText primary='My Profile' />
                            </ListItemButton>
                            <Divider />
                            <ListItem>
                                <ListItemText primary='Participant' primaryTypographyProps={{style: bold}} sx={{textAlign: 'center'}}></ListItemText>
                            </ListItem>
                            <ListItemButton component='a' href='/listimages'>
                                <ListItemIcon><ImageIcon /></ListItemIcon>
                                <ListItemText primary='Show Images' />
                            </ListItemButton>
                            <ListItemButton component='a' href='/'>
                                <ListItemIcon><ImageIcon /></ListItemIcon>
                                <ListItemText primary='New Image' />
                            </ListItemButton>
                            <Divider />
                            <ListItem>
                                <ListItemText primary='Facilitator' primaryTypographyProps={{style: bold}} sx={{textAlign: 'center'}}></ListItemText>
                            </ListItem>
                        </List>
                </Drawer>
            </Container>
        </AppBar>
    )
};

export default Header;