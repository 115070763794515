import React, {useState, useEffect} from "react";
import { Grid, CardActionArea, Typography, Card, Box, CircularProgress, Button } from "@mui/material";
import { getImages } from "../global/api";
import { useNavigate } from 'react-router-dom';
import * as colorConstants from '../global/ColorsConstants';
import * as navConstants from '../global/NavConstants';

const ListImages = () => {
    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        getImages().then(res => {
            if (res && res.statusCode) {
                let statusCode = res.statusCode;
                if (statusCode === '200' && res.body) {
                    let body = res.body;
                    if (body) {
                        setData(JSON.parse(body));
                    }
                }
            }
        })
        .finally(() => {
            setIsLoading(false);
        });
    }, []);

    function navigateToShowImage(fileName) {
        navigate(navConstants.SHOW_IMAGE, { state: { fileName: fileName}});
    }

    function getColor(color) {
        if (color === 'blue') {
            console.log(colorConstants.BLUE);
            return colorConstants.BLUE;
        } else if (color === 'orange') {
            return colorConstants.ORANGE;
        } else if (color === 'green') {
            return colorConstants.GREEN;
        } else if (color === 'gold') {
            return colorConstants.GOLD;
        }

        return colorConstants.DEFAULT;
    }

    function newImageHandler() {
        navigate(navConstants.NEW_IMAGE);
    }

    return (
        <Box style={{maxWidth: 950, margin: '0 auto', padding: '20px 5px'}}>
            <Typography gutterBottom variant='h5'>List Images</Typography>
            <Typography gutterBottom variant='body2' component='p' color='textSecondary'>Your created images</Typography>
            <Grid container spacing={2} mt={2} mb={2} p={2}>
                { isLoading ? <CircularProgress /> : 
                    data !== null && Array.isArray(data) && data.length ? data.map(item =>
                    <Grid xs={12} sm={6} item align='center' key={item.fileName}>
                        <Card sx={{maxWidth: 600}}>
                            <CardActionArea onClick={() => navigateToShowImage(item.fileName)}>
                                <Box sx={{padding: 2}}>
                                    <Typography display='inline' variant='h5' color={getColor(item.firstColor)}>{item.firstColor}&nbsp;</Typography>
                                    <Typography display='inline' variant='h5' color={getColor(item.secondColor)}>{item.secondColor}&nbsp;</Typography>
                                    <Typography display='inline' variant='h5' color={getColor(item.thirdColor)}>{item.thirdColor}&nbsp;</Typography>
                                    <Typography display='inline' variant='h5' color={getColor(item.fourthColor)}>{item.fourthColor}</Typography>
                                    <Typography variant='h5' color={colorConstants.EXTROVERT}>extrovert: {item.extroverted}</Typography>
                                    <Typography variant='h5' color={colorConstants.INTROVERT}>introvert: {item.introverted}</Typography>
                                    <Typography variant='body2'>Created on {item.imageCreatedDate}</Typography>
                                </Box>
                            </CardActionArea>
                        </Card>
                    </Grid>
                    ) : <Typography display='inline' variant='body'>You have not created any images.</Typography>
                }
            </Grid>
            <Button variant='contained' color='primary' onClick={newImageHandler} >New Image</Button>
        </Box>
    );
}

export default ListImages;