import React, {useState, useEffect} from "react";
import { CircularProgress, Card, CardContent, Typography, Grid } from "@mui/material";
import { useLocation } from 'react-router-dom';
import { getImage } from "../global/api";
import DownloadIcon from '@mui/icons-material/Download';

const ShowImage = () => {
    const {state} = useLocation();
    const {fileName} = state;

    const [imageData, setImageData] = useState('');
    const [imageHeight, setImageHeight] = useState('');
    const [imageWidth, setImageWidth] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        getImage(fileName).then(res => {
            if (res && res.statusCode) {
                let statusCode = res.statusCode;
                if (statusCode === '200' && res.body) {
                    let body = res.body;
                    if (body.imageHeight && body.imageWidth && body.base64EncodedImage) {
                        setImageData(body.base64EncodedImage);
                        setImageHeight(body.imageHeight);
                        setImageWidth(body.imageWidth);
                    }
                }
            }
        })
        .finally(() => {
            setIsLoading(false);
        });
    }, [fileName]);     // fileName here (as dependency) means this will fire whenever fileName changes

    function download(data) {
        var a = document.createElement('a');
        a.href = 'data:image/png;base64,' + data;
        a.download = 'spectrum-image.png';
        a.click();
    }

    return (
        <Card style={{maxWidth: 950, margin: '0 auto', padding: '20px 5px'}}>
            <CardContent justifycontent="center">
                { isLoading ? 
                    <CircularProgress /> :
                    <>
                        <Grid container spacing={1} mt={1} mb={1}>
                            <Grid xs={12} item align="center">
                                <Typography gutterBottom variant='h5'>Spectrum Image</Typography>
                                <Typography gutterBottom variant='body2' component='p' color='textSecondary'>Find your created image below</Typography>
                                <img src={`data:image/png;base64,${imageData}`} width={imageWidth} height={imageHeight} alt='spectrum' />
                                <Typography gutterBottom variant='body2' component='p' color='textSecondary'>Download</Typography>
                                <DownloadIcon 
                                    size='large' 
                                    sx={{border: '1px solid grey', borderRadius: 1}}
                                    onClick={() => download(imageData)} />
                            </Grid>
                        </Grid>
                    </>
                }
            </CardContent>
        </Card>
    )
};

export default ShowImage;