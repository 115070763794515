import React, {useState, useEffect} from "react";
import { Grid, Typography, Checkbox, FormControlLabel, Card, CardContent, MenuItem, Stack, TextField, Button, Snackbar, Alert, CircularProgress, Box } from "@mui/material";
import { createImage } from "../global/api";
import { useNavigate } from 'react-router-dom';
import { a } from "aws-amplify";

const NewImage = () => {
    const navigate = useNavigate();
    const [introvert, setIntrovert] = useState(0);
    const [extrovert, setExtrovert] = useState(0);
    const [firstColor, setFirstColor] = useState('');
    const [secondColor, setSecondColor] = useState('');
    const [thirdColor, setThirdColor] = useState('');
    const [fourthColor, setFourthColor] = useState('');

    const [firstSecondSpacing, setFirstSecondSpacing] = useState('');
    const [secondThirdSpacing, setSecondThirdSpacing] = useState('');
    const [thirdFourthSpacing, setThirdFourthSpacing] = useState('');

    const [sizing, setSizing] = useState('');
    const [spaceForName, setSpaceForName] = useState(false);
    const [name, setName] = useState('');

    const [extrovertErrorText, setExtrovertErrorText] = useState('');
    const [introvertErrorText, setIntrovertErrorText] = useState('');

    const [errorMessage, setErrorMessage] = useState('');
    const [snackOpen, setSnackOpen] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    let vertical = 'top';
    let horizontal = 'center';

    useEffect(() => {
        populateFromState();
    }, []);

    function populateFromState() {
        if (window.localStorage) {
            setExtrovert(window.localStorage.getItem('extroverted'));
            setIntrovert(window.localStorage.getItem('introverted'));
            setFirstColor(window.localStorage.getItem('firstColor'));
            setSecondColor(window.localStorage.getItem('secondColor'));
            setThirdColor(window.localStorage.getItem('thirdColor'));
            setFourthColor(window.localStorage.getItem('fourthColor'));
            setFirstSecondSpacing(window.localStorage.getItem('spacingBetweenFirstAndSecondColors'));
            setSecondThirdSpacing(window.localStorage.getItem('spacingBetweenSecondAndThirdColors'));
            setThirdFourthSpacing(window.localStorage.getItem('spacingBetweenThirdAndFourthColors'));
            setSizing(window.localStorage.getItem('sizing'));
            setSpaceForName(window.localStorage.getItem('spaceForName'));
            setName(window.localStorage.getItem('name'));
        }
    }

    function saveEntryState(){
        if (window.localStorage) {
            window.localStorage.setItem('extroverted', extrovert);
            window.localStorage.setItem('introverted', introvert);
            window.localStorage.setItem('firstColor', firstColor);
            window.localStorage.setItem('secondColor', secondColor);
            window.localStorage.setItem('thirdColor', thirdColor);
            window.localStorage.setItem('fourthColor', fourthColor);
            window.localStorage.setItem('spacingBetweenFirstAndSecondColors', firstSecondSpacing);
            window.localStorage.setItem('spacingBetweenSecondAndThirdColors', secondThirdSpacing);
            window.localStorage.setItem('spacingBetweenThirdAndFourthColors', thirdFourthSpacing);
            window.localStorage.setItem('sizing', sizing);
            window.localStorage.setItem('spaceForName', spaceForName);
            window.localStorage.setItem('name', name);
        }
    }

    function createImageHandler() {
        if ((Number(extrovert) + Number(introvert)) !== 10) {
            showError('Introverted and Extroverted combined must equal 10');
            return;
        }

        if (firstColor === '' || secondColor === '' || thirdColor === '' || fourthColor === '') {
            showError('All four colors are required');
            return;
        }

        if (firstColor === secondColor || secondColor === thirdColor || thirdColor === fourthColor || firstColor === thirdColor || firstColor === fourthColor || secondColor === fourthColor) {
            showError('All four temperament colors must be unique');
            return;
        }

        if (name && name.length > 20) {
            showError('Name must be 20 characters or less');
            return;
        }

        const data = { 
            "extroverted": extrovert,
            "introverted": introvert,
            "firstColor": firstColor, 
            "secondColor": secondColor, 
            "thirdColor": thirdColor, 
            "fourthColor": fourthColor,
            "spacingBetweenFirstAndSecondColors": firstSecondSpacing,
            "spacingBetweenSecondAndThirdColors": secondThirdSpacing,
            "spacingBetweenThirdAndFourthColors": thirdFourthSpacing,
            "sizing": sizing,
            "spaceForName": spaceForName,
            "name": name
        }

        setIsLoading(true);
        createImage(data)
            .then(res => {
                if (res && res.statusCode) {
                    let statusCode = res.statusCode;
                    if (statusCode === '200') {
                        if (res.body) {
                            let body = res.body;
                            if (body.imageFileName) {
                                saveEntryState();
                                let fileName = body.imageFileName;
                                navigate('/showimage', { state: { fileName: fileName}});
                            } else {
                                showError('Unexpected response from create image.  No image file');    
                            }
                        } else {
                            showError('Unexpected response from create image.  No body');
                        }
                    }
                    else {
                        if (res.body && res.body.message) {
                            showError(res.body.message);
                        }
                        else {
                            showError('Unknown error.  Try again.');
                            console.log(res);
                        }
                    }
                }
            }).catch(err => {
                showError('Unable to create image');
                console.log(err);
            }).finally(() =>{
                setIsLoading(false);
            });
    }

    function onExtrovertChanged(val) {
        if (val >= 1 && val <= 10) {
            setExtrovert(val);
            setExtrovertErrorText('');
        } else {
            setExtrovertErrorText('Value must be between 1 and 10');
        }
    }

    function onIntrovertChanged(val) {
        if (val >= 1 && val <= 10) {
            setIntrovert(val);
            setIntrovertErrorText('');
        } else {
            setIntrovertErrorText('Value must be between 1 and 10');
        }
    }

    function showError(msg) {
        setSnackOpen(true);
        setErrorMessage(msg);
    }
    function hideError(msg) {
        setSnackOpen(false);
        setErrorMessage('');
    }

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        hideError();
    }
    
    return (
        <Card style={{maxWidth: 950, margin: '0 auto', padding: '20px 5px'}}>
            <CardContent>
                <Snackbar open={snackOpen} autoHideDuration={6000} onClose={handleSnackClose} anchorOrigin={{ vertical, horizontal}} key={vertical + horizontal}>
                    <Alert severity='error' onClose={handleSnackClose} sx={{width: '100%'}}>{errorMessage}</Alert>
                </Snackbar>
                { isLoading ? <CircularProgress/> : null}
                <Typography gutterBottom variant='h5'>New Image</Typography>
                <Typography gutterBottom variant='body2' component='p' color='textSecondary'>Create a new spectrum image by completing the form below</Typography>
                <Box mt={3}>
                    <Typography gutterBottom variant='h6'>Interaction Style</Typography>
                    <Grid container spacing={1} mt={1} mb={1}>
                        <Grid xs={12} sm={6} item>
                            <TextField 
                                id="extroverted" 
                                label="Extroverted (0 to 10)" 
                                variant="outlined" 
                                value={extrovert}
                                fullWidth
                                required 
                                inputProps={{pattern: '[1-9]|10'}}
                                error={extrovertErrorText !== ''}
                                helperText={extrovertErrorText !== '' ? extrovertErrorText : ''}
                                onChange={(e) => onExtrovertChanged(e.target.value)}
                                disabled={isLoading} />
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <TextField 
                                id="introverted" 
                                label="Introverted (0 to 10)" 
                                variant="outlined" 
                                value={introvert}
                                fullWidth
                                required 
                                inputProps={{pattern: '[1-9]|10'}} 
                                error={introvertErrorText !== ''}
                                helperText={introvertErrorText !== '' ? introvertErrorText : ''}
                                onChange={(e) => onIntrovertChanged(e.target.value)} 
                                disabled={isLoading}/>
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={3}>
                    <Typography gutterBottom variant='h6'>Temperament Colors</Typography>
                    <Grid container spacing={1} mt={1} mb={1}>
                        <Grid xs={12} sm={6} item>
                            <TextField 
                            id="firstColor" 
                            label="First Color" 
                            variant="outlined" 
                            fullWidth
                            defaultValue=""
                            value={firstColor}
                            select 
                            required 
                            onChange={(e) => setFirstColor(e.target.value)} 
                            disabled={isLoading}>
                            <MenuItem value={'blue'}>Blue</MenuItem>
                            <MenuItem value={'gold'}>Gold</MenuItem>
                            <MenuItem value={'green'}>Green</MenuItem>
                            <MenuItem value={'orange'}>Orange</MenuItem>
                        </TextField>
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <TextField 
                            id="secondColor" 
                            label="Second Color" 
                            variant="outlined" 
                            fullWidth
                            defaultValue=""
                            value={secondColor}
                            select 
                            required 
                            onChange={(e) => setSecondColor(e.target.value)} 
                            disabled={isLoading}>
                            <MenuItem value={'blue'}>Blue</MenuItem>
                            <MenuItem value={'gold'}>Gold</MenuItem>
                            <MenuItem value={'green'}>Green</MenuItem>
                            <MenuItem value={'orange'}>Orange</MenuItem>
                        </TextField>
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <TextField 
                            id="thirdColor" 
                            label="Third Color" 
                            variant="outlined" 
                            fullWidth
                            defaultValue=""
                            value={thirdColor}
                            select 
                            required 
                            onChange={(e) => setThirdColor(e.target.value)} 
                            disabled={isLoading}>
                            <MenuItem value={'blue'}>Blue</MenuItem>
                            <MenuItem value={'gold'}>Gold</MenuItem>
                            <MenuItem value={'green'}>Green</MenuItem>
                            <MenuItem value={'orange'}>Orange</MenuItem>
                        </TextField>
                        </Grid>
                        <Grid xs={12} sm={6} item>
                            <TextField 
                            id="fourthColor" 
                            label="Fourth Color" 
                            variant="outlined" 
                            fullWidth
                            defaultValue=""
                            value={fourthColor}
                            select 
                            required 
                            onChange={(e) => setFourthColor(e.target.value)} 
                            disabled={isLoading}>
                            <MenuItem value={'blue'}>Blue</MenuItem>
                            <MenuItem value={'gold'}>Gold</MenuItem>
                            <MenuItem value={'green'}>Green</MenuItem>
                            <MenuItem value={'orange'}>Orange</MenuItem>
                        </TextField>
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={3}>
                    <Typography gutterBottom variant='h6'>Temperament Color Spacing</Typography>
                    <Grid container spacing={1} mt={1} mb={1}>
                        <Grid xs={12} item>
                            <TextField 
                                id="firstSecondSpacing" 
                                label="Spacing between first and second color" 
                                variant="outlined" 
                                fullWidth
                                defaultValue="" 
                                value={firstSecondSpacing}
                                select 
                                required 
                                onChange={(e) => setFirstSecondSpacing(e.target.value)} 
                                disabled={isLoading}>
                                <MenuItem value={'almostOverlapping'}>Almost overlapping</MenuItem>
                                <MenuItem value={'halfOverlapping'}>Half overlapping</MenuItem>
                                <MenuItem value={'somewhatOverlapping'}>Somewhat overlapping</MenuItem>
                                <MenuItem value={'edgesTouching'}>Edges touching</MenuItem>
                                <MenuItem value={'smallGapBetween'}>Small gap between</MenuItem>
                                <MenuItem value={'largeGapBetween'}>Large gap between</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid xs={12} item>
                            <TextField 
                                id="secondThirdSpacing" 
                                label="Spacing between second and third color" 
                                variant="outlined" 
                                fullWidth
                                defaultValue="" 
                                value={secondThirdSpacing}
                                select 
                                required 
                                onChange={(e) => setSecondThirdSpacing(e.target.value)} 
                                disabled={isLoading}>
                                <MenuItem value={'almostOverlapping'}>Almost overlapping</MenuItem>
                                <MenuItem value={'halfOverlapping'}>Half overlapping</MenuItem>
                                <MenuItem value={'somewhatOverlapping'}>Somewhat overlapping</MenuItem>
                                <MenuItem value={'edgesTouching'}>Edges touching</MenuItem>
                                <MenuItem value={'smallGapBetween'}>Small gap between</MenuItem>
                                <MenuItem value={'largeGapBetween'}>Large gap between</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid xs={12} item>
                            <TextField 
                                id="thirdFourthSpacing" 
                                label="Spacing between third and fourth color" 
                                variant="outlined" 
                                fullWidth
                                defaultValue="" 
                                value={thirdFourthSpacing}
                                select 
                                required 
                                onChange={(e) => setThirdFourthSpacing(e.target.value)} 
                                disabled={isLoading}>
                                <MenuItem value={'almostOverlapping'}>Almost overlapping</MenuItem>
                                <MenuItem value={'halfOverlapping'}>Half overlapping</MenuItem>
                                <MenuItem value={'somewhatOverlapping'}>Somewhat overlapping</MenuItem>
                                <MenuItem value={'edgesTouching'}>Edges touching</MenuItem>
                                <MenuItem value={'smallGapBetween'}>Small gap between</MenuItem>
                                <MenuItem value={'largeGapBetween'}>Large gap between</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={3}>
                    <Typography gutterBottom variant='h6'>Image Sizing</Typography>
                    <Grid container spacing={1} mt={1} mb={1}>
                        <Grid xs={12} item>
                            <TextField 
                                id="sizing" 
                                label="Sizing of Spectrum image" 
                                variant="outlined" 
                                fullWidth
                                defaultValue="" 
                                value={sizing}
                                select 
                                required 
                                onChange={(e) => setSizing(e.target.value)} 
                                disabled={isLoading}>
                                <MenuItem value={'small'}>Small</MenuItem>
                                <MenuItem value={'medium'}>Medium</MenuItem>
                                <MenuItem value={'large'}>Large</MenuItem>
                                <MenuItem value={'businesscard'}>Business Card</MenuItem>
                            </TextField>
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={3}>
                    <Typography gutterBottom variant='h6'>Name</Typography>
                    <Grid container spacing={1} mt={1} mb={1}>
                        <Grid xs={12} item>
                            <FormControlLabel
                                control={<Checkbox 
                                    id="spaceForName"
                                    checked={spaceForName}
                                    onChange={(e) => setSpaceForName(e.target.checked)}
                                    disabled={isLoading} />}
                                label="Include space for name" />
                        </Grid>
                        <Grid xs={12} item>
                            <TextField 
                                id="name" 
                                label="Name to include on image" 
                                variant="outlined" 
                                fullWidth
                                defaultValue="" 
                                value={name}
                                onChange={(e) => setName(e.target.value)} 
                                disabled={isLoading}>
                            </TextField>
                        </Grid>
                    </Grid>
                </Box>
                <Box mt={3}>
                    <Stack direction='row' spacing={1}>
                        <Button variant="contained" onClick={createImageHandler} disabled={isLoading}>Submit</Button>
                    </Stack>
                </Box>
            </CardContent>
        </Card>
    )
};

export default NewImage;