import {Auth} from 'aws-amplify'; 
const baseUrl = process.env.REACT_APP_SPECTRUM_API_BASE_URL;
const timeout = 5000;

export function getProfile() {
    const controller = new AbortController();
    setTimeout(() => controller.abort(), timeout);

    return getJwt().then(token => {
        return token;
    }).then(token => {
        return fetch(baseUrl + '/profile', {
            signal: controller.signal,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Amz-Security-Token': token
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        });
    });
}

export function updateProfile(data) {
    const controller = new AbortController();
    setTimeout(() => controller.abort(), timeout);

    return getJwt().then(token => {
        return token;
    }).then(token => {
        return fetch(baseUrl + '/profile', {
            signal: controller.signal,
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'X-Amz-Security-Token': token
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        });
    });
}

export function getImage(id) {
    const controller = new AbortController();
    setTimeout(() => controller.abort(), timeout);

    return getJwt().then(token => {
        return token;
    }).then(token => {
        return fetch(baseUrl + '/spectrumimage?fileName=' + id, {
            signal: controller.signal,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Amz-Security-Token': token
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        });
    });
}

export function getImages() {
    const controller = new AbortController();
    setTimeout(() => controller.abort(), timeout);

    return getJwt().then(token => {
        return token;
    }).then(token => {
        return fetch(baseUrl + '/images', {
            signal: controller.signal,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-Amz-Security-Token': token
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        });
    });
}

export function createImage(data) {
    const controller = new AbortController();
    setTimeout(() => controller.abort(), timeout);

    return getJwt().then(token => {
        return token;
    }).then(token => {
        return fetch(baseUrl + '/spectrumimage', {
            signal: controller.signal,
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'X-Amz-Security-Token': token
            }
        }).then(response => {
            if (response.ok) {
                return response.json();
            }
        });
    });
}

function getJwt() {
    return Auth.currentSession().then(res => {
        let accessToken = res.getIdToken()
        let jwt = accessToken.getJwtToken()
        return jwt;
    });
}