import './App.css';
import { Grid } from '@mui/material';
import Header from './components/Header';
import NewImage  from './pages/NewImage';
import ShowImage from './components/ShowImage';
import Profile from './components/Profile';
import ListImages from './pages/ListImages';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
import {View, withAuthenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';

Amplify.configure(awsconfig);

function App() {
  return (
    <Router>
      <View>
        <Grid container direction="column">
          <Grid item>
            <Header />
            <Routes>
              <Route path='/' element={<NewImage />} />
              <Route path='/showimage' element={<ShowImage />} />
              <Route path='/profile' element={<Profile />} />
              <Route path='/listimages' element={<ListImages />} />
            </Routes>
          </Grid>
        </Grid>
      </View>
    </Router>
  );
}

export default withAuthenticator(App);
